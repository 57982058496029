<template>
  <div class="all-solutions wrapper pageBox">
    <div class="banner">
      <div class="banner-text">
        <div class="text-main">
          <span class="span1">解决方案</span>
          <span class="span2">SOLUTION</span>
        </div>
        <div class="btn">为您解决智慧病房的全套设计方案</div>
      </div>
    </div>
    <div class="solutions-container">
      <div class="hot-content">
        <div class="hot-title">热门推荐</div>
        <div class="hot-card">
          <div v-for="(item, index) in hotList" :key="index" class="card-item" @click="handleGoto(item.path)">
            <div class="card-title">
              <img class="img" src="@/assets/img/card-1.png" alt="">
              <span>{{ item.title }}</span>
            </div>
            <div class="card-tip">{{ item.tip }}</div>
            <div class="card-btn">
              <span>去了解</span>
              <i class="el-icon-right" />
            </div>
          </div>
        </div>
      </div>
      <div class="all-content">
        <div class="all-title">全部解决方案</div>
        <div class="all-tabs">
          <el-tabs v-model="activeName">
            <el-tab-pane label="智慧医疗" name="first" />
            <el-tab-pane label="智慧服务" name="second" />
            <el-tab-pane label="智慧管理" name="third" />
            <el-tab-pane label="区域肿瘤/专病慢病防治平台" name="fourth" />
          </el-tabs>
        </div>
        <div class="all-card">
          <div v-for="(item, index) in introduceList" :key="index" class="card-item" @click="handleGoto(item.path)">
            <div class="card-title">
              <img class="img" src="@/assets/img/card-1.png" alt="">
              <span>{{ item.title }}</span>
            </div>
            <div class="card-tip">{{ item.tip }}</div>
            <div class="card-btn">
              <span>了解详情</span>
              <i class="el-icon-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const caseList = {
  first: [
    {
      title: '移动护理系统',
      tip: '医疗移动护理系统是借助移动信息技术，专为医疗护理工作打造的创新型应用系统，旨在打破传统护理工作的空间限制，让护理流程更便捷、高效。该系统通过移动终端设备，如平板电脑、移动护理车等，让护士能在患者床边实时开展各项护理操作。在患者信息采集上，护士可直接利用终端设备扫描患者腕带，快速准确地获取患者的基本信息、病史、检验检查结果等，无需再往返护士站查阅资料。护理记录也能即时完成，护士在护理操作后，即刻在移动终端上记录患者的生命体征、护理措施执行情况等，数据实时同步至医院信息系统，避免了事后补记可能出现的遗漏或错误。在医嘱执行方面，系统会实时接收并提醒护士新下达的医嘱，护士可直接在移动终端上查看详细内容，并依据医嘱为患者进行各项护理操作，同时对执行情况进行确认，确保医嘱执行的准确性和及时性。此外，系统还具备药品管理功能，护士在给患者发药时，通过扫描药品条码和患者腕带，即可快速核对药品信息，避免发药错误。医疗移动护理系统的应用，极大地提高了护理工作效率，减少了护理差错，让护士有更多时间直接护理患者，提升了患者的就医体验，为现代医疗护理工作的智能化、移动化发展提供了有力支持 。',
      path: '/smartWard/mobileNursing'
    },
    {
      title: '智慧病房整体解决方案',
      tip: '物联网智慧感知信息集成服务平台',
      path: '/smartWard/ward'
    },
    {
      title: '护士站智能交互系统',
      tip: '护士站智能交互系统通过放在护士站的智能交互大屏，与病房终端、医院His系统进行数据互联互通，实现院内数据统一整合显示。包括病区概况、患者信息、今日治疗、备忘录、交班信息、呼叫响应、健康宣教、护士排班、危急值预警等信息，帮助医护人员快速获取工作重点，优化工作流程，提高工作效率。',
      path: '/smartWard/nurseStation'
    },
    {
      title: '病房智能交互系统',
      tip: '基于物联网传输技术，实现医护人员与住院患者之间直接、可靠的信息联络对医院病床和呼叫设备统一进行管理，并引入智能穿戴设备的应用，护士在动态情况下也能够接收到病房患者的及时呼叫提醒。',
      path: '/smartWard/interaction'
    },
    {
      title: '床旁智能交互系统',
      tip: '床旁智能交互系统通过安装在患者床旁的智能交互屏配以机械摇臂的方式，可灵活跳动屏幕位置。系统实现医嘱查询、报告查询、健康宣教、通话呼叫、影音娱乐、订餐服务、医生查房、院内导航、体征录入等功能，提高患者的住院体验，方便医护人员进行床旁无纸化护理工作，提高护理工作效率。',
      path: '/smartWard/besideBed'
    },
    {
      title: '床位监测管理系统',
      tip: '床位监测管理系统通过在需要监护生命体征的患者病床上安装智能床垫，在不直接接触患者身体的情况下，测量、传输患者的心率、呼吸和离床、翻身状态等数据。通过高灵敏度压电薄膜传感器，捕捉到人体由心跳和呼吸引起的微小震动，获取准确的行为（在床和离床）与健康（心冲击图和呼吸波形图）数据。通过一阶滤波算法计算出用户的心率、呼吸率、心率变异性、睡眠分析数据，结合这些数据提供实时报警功能，并给出健康指导建议。',
      path: '/smartWard/bedMonitor'
    },
    {
      title: '智能输液监控系统',
      tip: '智能输液监控系统采用物联传感技术，基于多网合一基站无线传输，可实时监测输液滴速及输液完毕后自动报警并推送到护士站，同时支持实时获取 HIS中的数据信息，并通过终端设备更新显示病区床位患者信息及输液状态。',
      path: '/smartWard/infusion'
    }
  ],
  second: [
    {
      title: '互联网+护理上门',
      tip: '护理上门平台为以老年群体、院后康复群体、母婴群体为主的特殊群体提供智能化、专业化的居家护理、医学康复、母婴护理等护理延伸服务，精准对接人民群众多样化、多层次的健康需求。平台建设依托互联网等信息技术，利用在平台医疗机构下注册的护士，以“线上申请、线下服务”的模式，将护理服务从医疗机构延伸至家庭、社区，打通居家医养结合“最后一公里”。',
      path: '/smartWard/netNursing'
    },
    {
      title: '满意度调查系统',
      tip: '患者满意度是评价医院治疗结果和医疗服务质量的指标之一，也是医院开展“以患者为中心”改善服务质量的客观依据。患者满意度系统建立全院满意度调查体系，支持通过短信、微信、电话、平板电脑、二维码等多种调查方式开展调查工作，同时支持人工智能技术模拟真人对话场景，覆盖院内外所有满意度调查场景。',
      path: '/smartWard/satisfaction'
    },
    {
      title: '互联网医院',
      tip: '互联网医院就是用信息化、互联网化的手段去最大限度降低成本、优化服务流程，提升医疗服务诊前、诊中、诊后的全流程质量。互联网医院作为互联网医疗服务的承载形式之一，区别于公立医疗服务机构以及民营医疗服务机构。 同时还延伸出了很多新的服务态势，包括远程医疗、在线问诊、家庭医生签约服务等。包括了以互联网为载体和技术手段的健康教育、医疗信息查询、电子健康档案、疾病风险评估、在线疾病咨询、电子处方、远程会诊、远程治疗和康复等多种形式的健康医疗服务。',
      path: '/smartWard/netHospital'
    }
  ],
  third: [
    {
      title: '护理管理系统',
      tip: '护理管理信息系统具有管理全院护理人力资源、考核、培训、意外事件、满意度、工作量统计、危重病人访视、护理质控检查、护士长排班、实习护生、进修人员等功能。护士长科内提交信息，护理部根据电脑自动提示审管全院上报。利用护理管理系统,护理部不再需要人工整理科室上报文件，自动生成各类月度、季度、半年、全年统计图表，如意外事件发生数、平均满意度与排名、考核结果、培训情况、三级护理质量检查合格率/均分、全院人员考勤与值班小时数等等。护理管理系统可有效提高护理部管理执行力，是高效管理必备助手。',
      path: '/smartWard/nursing'
    },
    {
      title: '智慧医院运营管理系统（HRP）',
      tip: '一体化智慧医院运营管理平台，集成财务、人力资源、绩效考核、物资、设备等管理模块，通过数据共享与流程优化，实现医院资源的高效调配与利用，帮助医院实现精准采购、成本控制、人员绩效评估等，有效提升医院运营效率、服务质量和管理决策的科学性，增强医院竞争力，推动医院规范化、精细化发展，助力医院在复杂多变的医疗市场中稳健前行。',
      path: '/smartWard/hrp'
    },
    {
      title: '院内感染管理系统',
      tip: '系统能够实现与医院现有系统的数据对接，自动完成HIS、LIS、PACS、电子病历、手术麻醉、护理系统等系统的数据加载，并自动完成各项院感数据匹配工作，为医院感染管理工作人员提供详实的数据支持。',
      path: '/smartWard/infect'
    },
    {
      title: '智能终端运行保障系统',
      tip: '医院智能终端运行保障系统，通过AIoT物联网和AI、3D数字孪生，建立智慧医院可管可控一体化平台，提供从管理端-运营端-服务端-患者端全闭环一站式服务，全面打造医院智慧运行保障解决方案。',
      path: '/smartWard/support'
    }
  ],
  fourth: [
    {
      title: '区域肿瘤防治平台',
      tip: '区域肿瘤防治数字化平台是通过现代信息技术与医疗服务，提高肿瘤预防、治疗和康复水平。通过肿瘤知识的科普宣教，提升公众防癌意识、降低可预防肿瘤发生率；扩大居民的肿瘤筛查范围实现早诊早治；为肿瘤患者提供全“治疗、康复、随访”全生命周期的闭环管理。构建“防、筛、诊、治、康”五位一体的肿瘤防治体系。',
      path: '/platform/tumor'
    },
    {
      title: '专病（慢病）健康管理系统',
      tip: '区域慢病管理平台依据慢病管理规范和诊疗路径设计，应用大数据、物联网及人工智能技术，实现对慢病预防、诊断、治疗、康复各环节的全过程健康管理，集体征监测、健康评估、 病程管理、生活方式干预为一体，为区域慢病患者实现健康档案动态化 、 健康检测常态化 、 慢病管理数字化、健康教育普及化以及数据分析智能化。',
      path: '/platform/healthManage'
    }
  ]
};
export default {
  name: 'AllSolutions',
  components: {
  },
  data() {
    return {
      activeName: 'first',
      hotList: [
        {
          title: '移动护理系统',
          tip: '医疗移动护理系统是借助移动信息技术，专为医疗护理工作打造的创新型应用系统，旨在打破传统护理工作的空间限制，让护理流程更便捷、高效。该系统通过移动终端设备，如平板电脑、移动护理车等，让护士能在患者床边实时开展各项护理操作。在患者信息采集上，护士可直接利用终端设备扫描患者腕带，快速准确地获取患者的基本信息、病史、检验检查结果等，无需再往返护士站查阅资料。护理记录也能即时完成，护士在护理操作后，即刻在移动终端上记录患者的生命体征、护理措施执行情况等，数据实时同步至医院信息系统，避免了事后补记可能出现的遗漏或错误。在医嘱执行方面，系统会实时接收并提醒护士新下达的医嘱，护士可直接在移动终端上查看详细内容，并依据医嘱为患者进行各项护理操作，同时对执行情况进行确认，确保医嘱执行的准确性和及时性。此外，系统还具备药品管理功能，护士在给患者发药时，通过扫描药品条码和患者腕带，即可快速核对药品信息，避免发药错误。医疗移动护理系统的应用，极大地提高了护理工作效率，减少了护理差错，让护士有更多时间直接护理患者，提升了患者的就医体验，为现代医疗护理工作的智能化、移动化发展提供了有力支持 。',
          path: '/smartWard/mobileNursing'
        },
        {
          title: '护理管理系统',
          tip: '护理管理信息系统具有管理全院护理人力资源、考核、培训、意外事件、满意度、工作量统计、危重病人访视、护理质控检查、护士长排班、实习护生、进修人员等功能。护士长科内提交信息，护理部根据电脑自动提示审管全院上报。利用护理管理系统,护理部不再需要人工整理科室上报文件，自动生成各类月度、季度、半年、全年统计图表，如意外事件发生数、平均满意度与排名、考核结果、培训情况、三级护理质量检查合格率/均分、全院人员考勤与值班小时数等等。护理管理系统可有效提高护理部管理执行力，是高效管理必备助手。',
          path: '/smartWard/nursing'
        },
        {
          title: '智慧病房整体解决方案',
          tip: '物联网智慧感知信息集成服务平台',
          path: '/smartWard/ward'
        },
        {
          title: '病房智能交互系统',
          tip: '基于物联网传输技术，实现医护人员与住院患者之间直接、可靠的信息联络对医院病床和呼叫设备统一进行管理，并引入智能穿戴设备的应用，护士在动态情况下也能够接收到病房患者的及时呼叫提醒。',
          path: 'smartWard/interaction'
        }
      ]
    };
  },
  computed: {
    introduceList() {
      return caseList[this.activeName];
    }
  },
  methods: {
    handleGoto(path) {
      const routeData = this.$router.resolve({ path });
      window.open(routeData.href, '_blank');
      // this.$router.push({
      //   path
      // });
    }
  }
};
</script>
<style lang="scss" scoped>
.all-solutions {
  .banner {
    width: 100%;
    height: 560px;
    background: url('https://static.zhongjiadata.com/images/20250226/dfeaff10e2364b47b8bfdf9082be0d8e.png') no-repeat center;
    background-size: cover;
    .banner-text {
      padding-top: 218px;
      width: 1200px;
      margin: 0 auto;
      .text-main {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .span1 {
          font-size: 40px;
          line-height: 55px;
          color: #fff;
          font-weight: 600;
        }
        .span2 {
          font-size: 40px;
          margin-left: 20px;
          color: rgba(255,255,255,0.55);
          font-weight: 600;
        }
      }
      .btn {
        color: #ffffff;
        line-height: 22px;
        font-size: 16px;
      }
    }
  }
  .solutions-container {
    .hot-content {
      margin: 0 auto;
      margin-top: 88px;
      background-color: #fff;
       width: 1200px;
      .hot-title {
        text-align: center;
        font-size: 28px;
        color: #333333;
        font-weight: 600;
        margin-bottom: 60px;
      }
      .hot-card {
        display: flex;
        justify-content: space-between;
        padding-bottom: 60px;
        .card-item {
          width: 285px;
          height: 300px;
          background: #fff;
          border: 1px solid #dfdfdf;
          padding: 40px 20px;
          position: relative;
          cursor: pointer;
          &:hover {
            box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.10);
          }
          .card-title {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .img {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
            span {
              font-size: 18px;
              color: #333333;
              font-weight: 600;
              line-height: 25px;
            }
          }
          .card-tip {
            font-size: 16px;
            color: #5d7392;
            line-height: 22px;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .card-btn {
            position: absolute;
            right: 20px;
            bottom: 22px;
            display: flex;
            align-items: center;
            span {
              color: #333333;
              font-size: 16px;
              color:  #0279ff;
              line-height: 20px;
            }
            i {
              color: #0279ff;
              font-size: 16px;
            }
          }
        }
      }
    }
    .all-content {
      padding-top: 80px;
      background-color: #f7f8fe;;
      padding-bottom: 60px;
      .all-title {
        text-align: center;
        font-size: 28px;
        color: #333333;
        font-weight: 600;
        margin-bottom: 60px;
      }
      .all-tabs {
        width: 680px;
        margin: 0 auto;
        margin-bottom: 40px;
        /deep/.el-tabs {
          .el-tabs__nav {
            width: 100%;
            .el-tabs__item {
              min-width: 120px;
              padding: 0 36px !important;
              font-size: 16px;
              color: #333;
              font-weight: normal;
              height: 56px;
              line-height: 56px;
              &.is-active {
                color: #0279FF;
              }
            }
            .el-tabs__active-bar {
              color: #0279FF;
            }
          }
        }
      }
      .all-card {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        // justify-content: space-between;
        gap: 20px;
        padding-bottom: 60px;
        flex-wrap: wrap;
        .card-item {
          width: 386px;
          height: 300px;
          background: #fff;
          border: 1px solid #dfdfdf;
          padding: 40px 20px;
          position: relative;
          cursor: pointer;
          border-radius: 4px;
          margin-bottom: 20px;
          &:hover {
            box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.10);
            .card-btn  {
              background-color: #0279ff;
              span {
                color: #fff;
              }
              i {
                color: #fff;
              }
            }
          }
          &:nth-child(4n) {
            margin-right: 0;
          }
          .card-title {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .img {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
            span {
              font-size: 18px;
              color: #333333;
              font-weight: 600;
              line-height: 25px;
            }
          }
          .card-tip {
            font-size: 16px;
            color: #5d7392;
            line-height: 22px;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .card-btn {
            position: absolute;
            left: 20px;
            bottom: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 112px;
            height: 32px;
            border: 1px solid #0279ff;
            border-radius: 17px;
            span {
              font-size: 14px;
              color:  #0279ff;
              line-height: 20px;
            }
            i {
              color: #0279ff;
              font-size: 16px;
            }
            // &:hover {
            //   background-color: #0279ff;
            //   span {
            //     color: #fff;
            //   }
            //   i {
            //     color: #fff;
            //   }
            // }
          }
        }
      }
    }
  }
}
</style>

